import { useState, useEffect } from "react";

import { MenuContainer, MenuTabContainer } from "../styled/Container.js";
import { MenuHeader, MenuSubHeader, MenuItems, MenuImage } from "../styled/Menu.js";
import MenuItem from "../components/MenuItem.jsx";

import MenuNavbar from "../layout/MenuNavbar.jsx";
// import Footer from "../layout/Footer.jsx";

import { draftMenu } from "../seed.js";
import foodMenu from "../assets/images/food.png";
import drinksMenu from "../assets/images/drinks.png";
import logo from "../assets/images/VoidLogoWeb.png";



function Menu(props) {
  const [menu, setMenu] = useState(draftMenu);
  const [activeTab, setActiveTab] = useState('food');

 
 

  const displayActiveTab = (tab) => {

    const renderSwitch = (param) => {
      switch(param) {
        case 'food':
          return <MenuImage  src={foodMenu} />;
        case 'drinks':
          return <MenuImage  src={drinksMenu} />;
        default:
          return <img src={logo} width="90%"/>;
      }
    };

    return (
    <>
      {/* <MenuHeader>{`${tab}`}</MenuHeader> */}

      {
        <MenuItems>
          {renderSwitch(tab)}
        </MenuItems>
      //   {
      //     {
      //      'food': <span>yep</span>,
      //     'drinks': <img  src={drinksMenu} />
      //   } [{tab}]
      // }
      //     {/* {menu[tab === "N/A" ? "NABEV" : tab.toUpperCase()].map((item) =>
      //       item.subHeader ? (
      //         <MenuSubHeader>{item.subHeader}</MenuSubHeader>
      //       ) : (
      //         <MenuItem item={item} />
      //       )
      //     )} */}
       }
    </>
  )
      };
  
  const displayAllTabs = Object.keys(menu).map((category) => (
    <>
      {/* <MenuHeader>{category === "NABEV" ? "N/A" : `${category}`}</MenuHeader>
      <MenuItems>
        {menu[category].map((item) =>
          item.subHeader ? (
            <MenuSubHeader>{item.subHeader}</MenuSubHeader>
          ) : (
            <MenuItem item={item} />
          )
        )}
      </MenuItems> */}
      {displayActiveTab(category === "NABEV" ? "N/A" : `${category}`)}
    </>
  ));

  // useEffect(() => {
  // fetch menu eventually
  // },[]);

  return (
    <MenuContainer>
      <MenuNavbar activeTab={activeTab} setActiveTab={setActiveTab} />
      <MenuTabContainer>
        {activeTab ? displayActiveTab(activeTab) : displayAllTabs}
      </MenuTabContainer>
      {/* <Footer/> */}
    </MenuContainer>
  );
}

export default Menu;
